<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: signUpStatus.vue
Description: This file contains vue component which used to display the status of the user registration process
-->
<template>
  <el-row class="sign-up-status-card">
    <el-col :span="10" :offset="7">
      <card>
        <template slot="title">
          <template v-if="message_status === 'success'">
            <h2>{{ $t("signed-up-successfully") }}</h2>
          </template>
          <template v-if="page === 'email_verify'">
            <h2>{{ $t("email_verify_expire_title") }}</h2>
          </template>
        </template>
        <template slot="form">
          <center>
            <div class="sign-up-field-label">{{ $t(message_key) }}</div>
            <br />
            <er-button
              size="small"
              type="plain"
              class="btn_back_to_login"
              @click="rdm__navigateToSignIn"
              style="margin-bottom: 20px"
              >{{ $t("Back") }} {{ $t("Comn_to") }}
              {{ $t("Harvest_log_in") }}</er-button
            >
            <br />
            <div class="sign-up-field-label">
              {{ $t("text_above_resend_mail_button") }}
            </div>
            <br />
            <er-button
              @click="handleResendEmail"
              type="primary"
              size="small"
              round
              >{{ $t("btn_resend_mail_text") }}</er-button
            >
          </center>
        </template>
      </card>
    </el-col>
  </el-row>
</template>

<script>
import card from "./card";
import redirectsMixin from "@/mixins/redirectsMixin.js";
export default {
  mixins: [redirectsMixin],
  props: {
    message_key: {
      default: "comfirm_email_request_message"
    },
    message_status: {
      default: "success"
    },
    page: {
      default: "sign_up"
    }
  },
  components: {
    card
  },
  data: function() {
    return {};
  },
  methods: {
    handleResendEmail() {
      this.$router.push("/resend-email");
    }
  }
};
</script>

<style lang="scss">
.sign-up-status-card {
  .sign-up-field-label {
    color: #909399;
    margin-bottom: 5px;
  }
  .el-col {
    height: 100%;
  }

  .el-card {
    height: 100%;
  }
}
</style>
