<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: emailVerify.vue
Description: This file contains the form that handles the redirection from backend for email verfication screen to the pondlogs site
-->
<template>
  <el-row>
    <el-col :span="10" :offset="7">
      <card class="email-verify">
        <template slot="title">
          <h2>{{ $t("Comn_email_loading") }}</h2>
        </template>
        <template slot="form">
          <div class="loader loader1">
            <div>
              <div>
                <div>
                  <div>
                    <div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </card>
    </el-col>
  </el-row>
</template>

<script>
import card from "./card";
export default {
  mounted () {
    this.verifyEmail();
  },
  components: {
    card
  },
  data: function () {
    return {
      loading: false
    };
  },
  methods: {
    verifyEmail () {
      this.loading = true;
      this.$store
        .dispatch("auth/verifyEmailRequest", this.$route.query)
        .then(response => {
          const message = this.$t("email_verify_success_message");
          this.$notify({
            title: this.$t("email_verify_success_title"),
            dangerouslyUseHTMLString: true,
            message: message,
            duration: 10000,
            type: "success"
          });
          this.$router.push("/sign-in");
        })
        .catch(err => {
          console.error(err);
          const emailVerifyErrorMsg = this.$t("Usrs_confirm_link_not_exists");
          this.$notify({
            title: this.$t("email_verify_expire_title"),
            message: emailVerifyErrorMsg,
            dangerouslyUseHTMLString: true,
            duration: 5000,
            type: "error"
          });
          this.$router.push({
            name: "SignUpStatus",
            params: {
              message_key: "Usrs_confirm_link_not_exists",
              message_status: "failed",
              page: "email_verify"
            }
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
$grey: rgba(0, 0, 0, 0.5);
$blue: rgba(0, 0, 255, 0.5);

@mixin loaderDivMixin {
  border-radius: 50%;
  padding: 8px;
  border: 2px solid transparent;
  animation: rotate linear 3.5s infinite;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.email-verify {
  overflow: hidden;
  h3 {
    display: block;
    margin: 0px auto;
    text-align: center;
    font-weight: lighter;
    color: $grey;
    letter-spacing: 1.5px;
  }
  h1 {
    margin: 50px auto;
  }
  .loader {
    position: relative;
    margin: 75px auto;
    width: 150px;
    height: 150px;
    display: block;
    overflow: hidden;
    div {
      height: 100%;
    }
  }
  /* loader 1 */
  .loader1,
  .loader1 div {
    @include loaderDivMixin;
    border-top-color: $grey;
    border-bottom-color: $blue;
  }
  .loader,
  .loader * {
    will-change: transform;
  }
  * {
    box-sizing: border-box;
  }
}
</style>
