<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: resendEmail.vue
Description: This file contains vue component which handles the resending email functionality.
-->
<template>
  <el-row type="flex" class="resend-email-card">
    <!-- <el-col></el-col> -->
    <el-col :span="10" :offset="7">
      <card class="resend-email generic-card">
        <template slot="title">
          <h2>{{ $t("resend_email") }}</h2>
        </template>
        <template slot="form">
          <el-alert
            v-if="ehm__unhandledErrorMessage"
            :title="$t(ehm__unhandledErrorMessage)"
            type="error"
            @close="ehm__handleAlertClose"
          ></el-alert>
          <er-form
            :ref="formName"
            size="small"
            @submit.native.prevent="sumbitResendEmailDetails"
            v-loading="loading"
            element-loading-background="white"
            :element-loading-text="$t('Comn_auth_loading')"
            :model="formModel"
          >
            <div class="form-field-label">
              <center>{{ $t("resend_mail_message") }}</center>
            </div>
            <er-form-item
              prop="email"
              :error="aekmm__castErrorKeyToLang(ehm__errMessagesObject.email)"
            >
              <er-input
                class="input-box"
                v-model="formModel.email"
                @keyup.native.enter="sumbitResendEmailDetails"
                :placeholder="$t('Usrs_enter_email_addr')"
              >
                <template slot="prepend">
                  <span class="material-icons-round icon--prefix"> email </span>
                </template>
              </er-input>
            </er-form-item>
          </er-form>
          <layout-toolbar justify="middle">
            <er-button
              size="small"
              class="btn_back_to_login"
              @click="navigate('sign-in')"
            >
              {{ $t("Back") }} {{ $t("Comn_to") }}
              {{ $t("Harvest_log_in") }}
            </er-button>
            <er-button
              size="small"
              class="btn_submit"
              @click="sumbitResendEmailDetails"
              >{{ $t("btn_resend_mail_text") }}
            </er-button>
          </layout-toolbar>
        </template>
      </card>
    </el-col>
  </el-row>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import authErrorKeyMapMixin from "@/mixins/authErrorKeyMapMixin.js";
import {
  erButton,
  erForm,
  erInput,
  erFormItem
} from "@/components/base/basePondlogs.js";
import card from "./card";
import { mapActions } from "vuex";
import formRulesMixin from "@/mixins/formRulesMixin";
export default {
  mixins: [errorHandlerMixin, authErrorKeyMapMixin, formRulesMixin],
  components: {
    erButton,
    erForm,
    erInput,
    erFormItem,
    card
  },
  data: function () {
    return {
      formModel: { email: "" },
      ehm__errMessagesObject: { email: "" },
      loading: false,
      formName: "resendEmailForm"
    };
  },
  methods: {
    ...mapActions("auth", {
      resendVerifyEmail: "resendVerifyEmail"
    }),
    navigate(component) {
      this.$router.push("/" + component);
    },
    ehm__error409Handler: function (error) {
      console.error(error);
      this.ehm__unhandledErrorMessage = "email_is_already_verified";
    },
    async sumbitResendEmailDetails() {
      if (this.loading) {
        return;
      }

      try {
        this.loading = true;
        this.ehm__errMessagesObject = { email: "" };
        await this.resendVerifyEmail(this.formModel);
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: this.$t("Usrs_mail_sent_to_email"),
          duration: 5000,
          type: "success"
        });
        this.$router.push("/sign-in");
      } catch (err) {
        this.ehm__errorMessages(err, false);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss">
.resend-email-card {
  .el-card {
    height: 100%;
  }
}
</style>
